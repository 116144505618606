import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
        var about = this.props.data.about;
        var aboutImage = this.props.data.aboutImage;
    }

    return (
      <section id="about">
        <div className="brook-whatdo-area section-ptb-50 bg_color--39">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="thumb">
                            <img className="w-100" src={`img/about/${aboutImage}`} alt="about images"/>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_sm--40 mt_md--40">
                        <div className="bk-title--default text-left">
                            <h5 className="heading heading-h5 theme-color wow move-up">Who We Are</h5>
                            <h3 className="heading heading-h3 wow move-up text-white">Innovative Designers</h3>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="what-do mt--30">
                                    <div className="content">
                                        <p className="bk_pra wow move-up text-white">{about}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </section>
    );
  }
}

export default About;
