import React, { Component } from 'react';
import axios from 'axios';

class Contact extends Component {
   handleSubmit(e) {
      e.preventDefault();
      const name = document.getElementById('contactName').value;
      const email = document.getElementById('contactEmail').value;
      const subject = document.getElementById('contactSubject').value || `Contact Request from ${name}`;
      const message = document.getElementById('contactMessage').value;
      const phone = document.getElementById('contactPhone').value;
      const body = `${message}\n\nPhone Number: ${phone}`;
      const priority = document.getElementById('contactPriority').value || 'normal';
      const data = {
            name: name,
            email: email,
            subject: subject,
            messsage: body,
            priority: priority
         }
      axios.post('http://billyjbryant:3002/send', data)
         .then((response) => {
         if (response.data.msg === 'success') {
            alert("Message Sent.");
            this.resetForm()
         } else if (response.data.msg === 'fail') {
            alert("Message failed to send.")
            }
         })
         .catch(() => {
            console.error('Message not sent')
         });
   }

   resetForm(){
      document.getElementById('contactForm').reset();
   }

  render() {
   if(this.props.data){
      var message = this.props.data.contactmessage;
   }

     return (
        <section id="contact"><div className="separator"></div>
             <div className="bk-contact-area section-ptb-50 bg_color--28">
                <div className="container">
                     <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-xl-8 col-lg-9 col-12 mt_md--40 mt_sm--40">
                            <h2 className="heading heading-h2 text-center text-white">Contact Us Today!</h2>
                            <div className="contact-form">
                                <form className="form-style--1 wow move-up" action='' id="contactForm" name="contactForm" method="POST">
                                    <div className="row">

                                        <div className="col-lg-6">
                                            <div className="input-box">
                                                <span className="form-icon far fa-user"></span>
                                                <input type="text" placeholder="Name *" id="contactName" name="contactName" onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input-box">
                                                <span className="form-icon far fa-envelope"></span>
                                                <input type="text" placeholder="Email *" id="contactEmail" name="contactEmail" aria-describedby="emailHelp" onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input-box">
                                                <span className="form-icon fas fa-mobile-alt"></span>
                                                <input type="text" placeholder="Phone number" id="contactPhone" name="contactPhone" aria-describedby="phoneHelp" onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input-box">
                                                <span className="form-icon fas fa-exclamation-circle"></span>
                                                <select type="dropdown" defaultValue="normal" id="contactPriority" name="contactPriority" onChange={this.handleChange}>
                                                   <option value="normal">Normal</option>
                                                   <option value="low">Low</option>
                                                   <option value="high">High</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="input-box">
                                                <span className="form-icon fas fa-file-alt"></span>
                                                <input type="text" placeholder="Subject" id="contactSubject" name="contactSubject" onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="input-box">
                                                <textarea placeholder="Your message" id="contactMessage" name="contactMessage"></textarea>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-action">
                                                <div className="form-description">
                                                    {message}
                                                </div>
                                                <div className="form-submit">
                                                     <button type="submit" className="brook-btn bk-btn-theme btn-sd-size btn-rounded space-between submit">Send Message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                         </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </div>
            <div id="message-success" className="message-success" style={{display:"none"}}>
                <div className="col-lg-6">
                    <div className="message-box bg_cat--3 mt--30 move-up wow">
                        <div className="icon">
                            <i className="fas fa-check-circle"></i>
                        </div>
                        <div className="content">Your message was sent, Thank You!</div>
                    </div>
                </div>
            </div>
            <div id="message-warning" className="message-warning" style={{display:"none"}}>
                <div className="col-lg-6">
                    <div className="message-box bg_cat--2 mt_md--30 mt_sm--30 move-up wow">
                        <div className="icon">
                            <i className="fas fa-bell"></i>
                        </div>
                        <div className="content" id="message-warning-msg">Your message was not sent!</div>
                    </div>
                </div>
            </div>
</section>
    );
  }
}

export default Contact;
