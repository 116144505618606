import React, { Component } from 'react';
import ModalVideo from 'react-modal-video'


class Header extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({isOpen: true})
  }
  render() {

    if(this.props.data){
        var title = this.props.data.title;
        var website = this.props.data.website;
        var logo = `/img/brand/${this.props.data.logo}`;
        var image = `/img/${this.props.data.image}`;
        var videoId = this.props.data.videoId;
        var description = this.props.data.description;
        var desc1 = [description.split(' ')[0], description.split(' ')[1]].join(' ')
        var desc2 = description.split(' ')[2]
        var context = this.props.data.context;
        var networks = this.props.data.social.map(function(network){
            return <li key={network.name} className={network.name}><a href={network.url} className="link" target='_blank' rel="noopener noreferrer" aria-label={network.name}><i className={network.className}></i></a></li>
        })
    }

      return (<section id="header">
        <header className="br_header header-default header-transparent light-logo--version haeder-fixed-width headroom--sticky clearfix">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="header__wrapper mr--0">
                            <div className="header-left flex-20">
                                <div className="logo">
                                      <a href={website}>
                                          <img src={logo} alt={title}/>
                                    </a>
                                </div>
                            </div>
                            <div className="header-flex-right flex-80">
                                <div className="mainmenu-wrapper have-not-flex d-none d-lg-block">
                                    <nav className="page_nav">
                                        <ul className="mainmenu">
                                            <li key="header-home" className="lavel-1"><a href="#header"><span>Home</span></a></li>
                                            <li key="header-about" className="lavel-1"><a href="#about"><span>About</span></a></li>
                                            <li key="header-team" className="lavel-1"><a href="#team"><span>Team</span></a></li>
                                            <li key="header-portfolio" className="lavel-1"><a href="#portfolio"><span>Portfolio</span></a></li>
                                            <li key="header-testimonials" className="lavel-1"><a href="#testimonials"><span>Testimonials</span></a></li>
                                            <li key="header-contact" className="lavel-1"><a href="#contact"><span>Contact</span></a></li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="header-right have-not-flex d-sm-flex pl--35 pr_md--40 pr_sm--40">
                                    <ul className="social-icon icon-size-medium text-center tooltip-layout d-none d-sm-flex">
                                          {networks}
                                    </ul>
                                    <div className="manu-hamber popup-mobile-click d-lg-none light-version d-block d-xl-none pl_md--20 pl_sm--20">
                                        <div>
                                            <i></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div className="popup-mobile-manu popup-mobile-visiable">
            <div className="inner">
                <div className="mobileheader">
                    <div className="logo">
                        <a href={website}>
                            <img src={logo} alt="Multipurpose"/>
                        </a>
                    </div>
                    <a className="mobile-close" href="#header">X</a>
                </div>
                <div className="menu-content">
                    <ul className="menulist object-custom-menu">
                        <li key="mobile-home" className="lavel-1"><a href="#header"><span>Home</span></a></li>
                        <li key="mobile-about" className="lavel-1"><a href="#about"><span>About</span></a></li>
                        <li key="mobile-team" className="lavel-1"><a href="#team"><span>Team</span></a></li>
                        <li key="mobile-portfolio" className="lavel-1"><a href="#portfolio"><span>Portfolio</span></a></li>
                        <li key="mobile-testimonials" className="lavel-1"><a href="#testimonials"><span>Testimonials</span></a></li>
                        <li key="mobile-contact" className="lavel-1"><a href="#contact"><span>Contact</span></a></li>
                    </ul>
                </div>
            </div>
          </div>
          <div className="revolution-slider-area slider-bg-1 slider-bg-2 rslide" style={{backgroundImage: `url(${image})`,backgroundPosition: "center center"}} data-background="#3d088e">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner pt--290 pb--170 text-center">
                                        <h5 className="heading heading-h5 text-white">{context}</h5>
                                        <div className="bkseparator--15"></div>
                                  <h1 className="heading heading-h1 text-white font-120">{desc1} <span style={{fontStyle:"italic"}}>{desc2}</span></h1>
                              <div className="video-btn mb--100">
                                          <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={videoId} onClose={() => this.setState({isOpen: false})} />
                                            <button className="brook-btn bk-btn-gradient-pink btn-sd-size btn-rounded space-between" onClick={this.openModal}>Follow the Leader</button>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div className="basic-modern-dots white-dots">
                                            <div className="dot first-circle"></div>
                                            <div className="dot second-circle"></div>
                                            <div className="dot third-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
    </section>);
  }
}

export default Header;
