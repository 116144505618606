import React, { Component } from 'react';

class Team extends Component {
  render() {

    if (this.props.data) {
      var members = this.props.data.members.map(function (members) {
        var image = '/img/team/' + members.image;
        var social = members.social.map(function (network) {
          return <li key={`team-${members.name}-${network.name}`} className={network.name}><a href={network.url} className="link" target='_blank' rel="noopener noreferrer" aria-label={network.name}><i className={network.className}></i></a></li>
        })
        return (
          <div key={members.name} className={`col-lg-4 col-md-4 col-sm-6 col-12`}>
                            <div className="team team__style--3">
                                <div className="thumb">
                                    <img src={image} alt={members.name}/>
                                        <div className="overlay" style={{backgroundImage:`url(${image})`}}></div>
                                    <ul className="social-icon icon-solid-rounded icon-size-medium text-center">
                                      {social}
                                    </ul>
                                </div>
                                <div className="team-info text-center">
                                    <div className="info">
                                        <h5>{members.name}</h5>
                                        <span>{members.title}</span>
                                    </div>
                                </div>
                            </div>
                        </div>)
      })
    }

    return (
      <section id="team">
        <div className="brook-team-area section-ptb-50 bg_color--1">
          <div className="container">
                            <h2 className="heading heading-h2 text-center">Meet The Team</h2>
            <div className="row row--30">
              <div className="col-lg-2 col-md-2 col-sm-3 col-12"></div>
              {members}
              <div className="col-lg-2 col-md-2 col-sm-3 col-12"></div>
            </div>
          </div>
        </div>
      </section>);
  }
}

export default Team;
