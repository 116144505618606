import React, { Component } from 'react';
import Slider from "react-slick";

class Testimonials extends Component {
   render() {
        function PrevButton({ onClick }) {
        return <button onClick={onClick} className="slick-btn slick-prev" style={{ left: 0 }}><i className="fas fa-angle-left"></i></button>;
        }

        function NextButton({ onClick }) {
        return <button onClick={onClick} className="slick-btn slick-next" style={{ right: 0 }}><i className="fas fa-angle-right"></i></button>;
        }
        var TestiomnialSliderSettings = {
            "className": "brook-element-carousel slick-arrow-center slick-dots-bottom wow move-up",
            "dotsClass": "slick-dots",
            "spaceBetween": 50, 
            "slidesToShow": 2, 
            "slidesToScroll": 1, 
            "arrows": true, 
            "infinite": true,
            "dots": true,
            "prevArrow": <PrevButton/>,
            "nextArrow": <NextButton/>,
            "responsive": [
                {"breakpoint":770, "settings": {"slidesToShow": 2}},
                {"breakpoint":577, "settings": {"slidesToShow": 1}},
                {"breakpoint":481, "settings": {"slidesToShow": 1}}
            ]
        };

    if(this.props.data){
      var testimonials = this.props.data.testimonials.map(function(testimonials){
          return (<div key={testimonials.user} id="container" className="testimonial bg_color--7 " style={{ height:"330px",margin: "0 15px" }}>
           <div className="testimonial testimonial_style--1" style={{ height:"330px!important",'margin': "0 15px" }}>
              
                                    <div className="content">
                                        <p className="bk_pra">“{testimonials.text}”</p>
                                        <div className="testimonial-info">
                                            <div className="post-thumbnail">
                                                <img src={`img/testimonial/${testimonials.image}`} alt={testimonials.user}/>
                                            </div>
                                            <div className="client-info">
                                                <h6>{testimonials.user}</h6>
                                                <span>{testimonials.title}</span><br/>
                                                <span><a href={testimonials.website} target="_blank" rel="noopener noreferrer">{testimonials.company}</a></span>
                                            </div>
                                        </div>
                                        <div className="testimonial-quote">
                                            <span className="fa fa-quote-right"></span>
                                        </div>
                                    </div></div>
                                </div>)
      })
    }

    return (
       <section id="testimonials">
          <div className="brook-testimonial-area ptb--150 ptb-md--80 ptb-sm--60 bg_color--7 slick-arrow-hover">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 pb--30">
                   <Slider {...TestiomnialSliderSettings} className="brook-element-carousel slick-arrow-center slick-dots-bottom wow move-up">
                      {testimonials}
                        </Slider>
                        </div>
                    </div>
                </div>
            </div>
      </section>
    );
  }
}

export default Testimonials;
