import React, { Component } from 'react';
import Zoom from 'react-reveal/Zoom';

class Portfolio extends Component {
    render() {
        if (this.props.data) {
            var totalPixels = 0;
            var totalProjects = 0;
            var projects = this.props.data.projects.map(function(project){
                var projectImage = 'img/portfolio/' + project.image;
                totalPixels += parseInt(project.pixels);
                totalProjects += 1;
                return (
                    <div key={project.title} className="col-lg-4 col-sm-6 col-12 move-up wow">
                            <div className="portfolio portfolio_style--1 mt--30">
                                <div className="thumb">
                                <img src={projectImage} alt={project.title}/>
                                </div>
                                <div className="port-overlay-info">
                                    <div className="hover-action">
                                        <h3 className="post-overlay-title"><a href="#portfolio">{project.title}</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>)
            });
            var averagePixels = totalPixels / totalProjects;
        }

        return (
            <section id="portfolio">
                <div className="brook-row-column-area bg_color--39 section-ptb-50 poss_relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div className="brook-counter text-center">
                                    <div className="icon">
                                        <i className="far fa-list-alt"></i>
                                    </div>
                                    <div className="content">
                                        <span className="count text-white">{totalProjects}</span>
                                        <h6 className="heading heading-h6 text-white">Completed Projects</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt_mobile--40">
                                <div className="brook-counter text-center">
                                    <div className="icon">
                                        <i className="far fa-sun"></i>
                                    </div>
                                    <div className="content">
                                        <span className="count text-white">{averagePixels}</span>
                                        <h6 className="heading heading-h6 text-white">Average Pixels per Project</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt_sm--40">
                                <div className="brook-counter text-center">
                                    <div className="icon">
                                        <i className="far fa-lightbulb"></i>
                                    </div>
                                    <div className="content">
                                        <span className="count text-white">{totalPixels}</span>
                                        <h6 className="heading heading-h6 text-white">Total Number of Pixels</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="brook-portfolio-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1" >
                    <Zoom top><div><h3 className="heading heading-h3 text-center" style={{fontVariant:"small-caps"}}>This is what we do!</h3></div></Zoom>
                    <div className="container" style={{marginBottom:'2em'}}>
                        <div className="row">
                            <Zoom bottom>
                                {projects}
                            </Zoom>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Portfolio;
