import React, { Component } from 'react';
import axios from 'axios';

class Footer extends Component {
    state = {
        instagramData: []
    };
    
    PROXY_BASE = 'https://api.totilight.io/api/';

    componentDidMount () {
        const mediaFields = 'id,media_url,thumbnail_url,permalink,caption'
        const queryFields = {
            "fields": mediaFields
        }
        const query = new URLSearchParams(queryFields).toString()
        axios.get(`${this.PROXY_BASE}instagram?${query}`)
            .then(({ data }) => {
                this.setState({ instagramData: data.data })
            })
            .catch(({ error }) => {
                console.error({ 'Error': error })
            });
    }
    render() {
        const instaFeed = this.state.instagramData;
        if (instaFeed) {
            var instagram = instaFeed.slice(0, 9).map(function (post) {
                var id = post.id;
                var imgSrc = ('thumbnail_url' in post) ? post.thumbnail_url : post.media_url;
                var likes = ('like_count' in post) ? post.like_count : null;
                var comments = ('comments_count' in post) ? post.comments_count : null;
                var caption = post.caption;
                var permalink = post.permalink;
                // eslint-disable-next-line
                var likesLink = (likes) ? `<a href=${permalink} target="_blank" rel="noopener noreferrer"><i className="fas fa-heart"></i>${likes}</a>` : '';
                // eslint-disable-next-line
                var commsLink = (comments) ? `<a href=${permalink} target="_blank" rel="noopener noreferrer"><i className="fas fa-comment-dots"></i>${comments}</a>` : ''
                return (
                    <div key={id} className="item-grid grid-style--1">
                        <div className="thumb">
                            <a href={permalink} target="_blank" rel="noopener noreferrer">
                                <img src={imgSrc} alt={caption} />
                            </a>
                        </div>
                    </div>)
            })
        }
        if (this.props.data) {
            var networks = this.props.data.social.map(function (network) {
                return <a key={network.name} href={network.url} target='_blank' rel="noopener noreferrer"><i className={network.className}></i></a>
            })
            var title = this.props.data.title;
            var company = this.props.data.company;
            var website = this.props.data.website;
            var logo = `/img/brand/${this.props.data.logo}`;
            var email = this.props.data.email;
            var phone = this.props.data.phone;
            var city = this.props.data.address.city;
            var state = this.props.data.address.state;
            var about = this.props.data.aboutShort;
        }
        var year = new Date().getFullYear();
        const logoWidth = "40%";
        return (<section id="footer">
            <footer className="page-footer bg_color--7 pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5">
                <div className="bk-footer-inner pt--150 pb--30 pt_sm--100">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="footer-widget text-var--2">
                                    <div className="logo">
                                        <a href="#header">
                                            <img src={logo} alt={title} style={{ maxWidth: logoWidth }} />
                                        </a>
                                    </div>
                                    <div className="footer-inner">
                                        <p>{about}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_mobile--40">
                                <div className="footer-widget text-var--2 menu--about">
                                    <h2 className="widgettitle">About us</h2>
                                    <div className="footer-menu">
                                        <ul className="ft-menu-list bk-hover">
                                            <li key="footer-about"><a href="#about">About Us</a></li>
                                            <li key="footer-team"><a href="#team">Team</a></li>
                                            <li key="footer-portfolio"><a href="#portfolio">Portfolio</a></li>
                                            <li key="footer-testimonials"><a href="#testimonials">Testimonials</a></li>
                                            <li key="footer-contact"><a href="#contact">Contact</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                <div className="footer-widget text-var--2 menu--contact">
                                    <h2 className="widgettitle">Contact</h2>
                                    <div className="footer-address">
                                        <div className="bk-hover">
                                            <p>{city}, {state}</p>
                                            <p><a href={`mailto:${email}`}>{email}</a></p>
                                            <p><a href={`tel:${phone}`}>{phone}</a></p>
                                        </div>
                                        <div className="social-share social--transparent text-white">
                                            {networks}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                <div className="footer-widget text-var--2 menu--instagram">
                                    <h2 className="widgettitle">Instagram</h2>

                                    <div className="ft-instagram-list">

                                        <div className="instagram-grid-wrap">

                                            {instagram}

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="copyright ptb--50 text-var-2">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="copyright-left text-md-left text-center">
                                    <ul className="bk-copyright-menu d-flex bk-hover justify-content-center justify-content-md-start flex-wrap flex-sm-nowrap">
                                        <li key="copy-portfolio"><a href="#portfolio">Latest projects</a></li>
                                        <li key="copy-contact"><a href="#contact">Contact us</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="copyright-right text-md-right text-center">
                                    <p>&copy; {year} {company}. <a href={website}>All Rights Reserved.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </section>);
    }
}

export default Footer;
